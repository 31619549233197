
import * as _ from 'lodash';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent(
    {
      name: 'Project',
      data() {
        return {
          model: {
            name: '',
            number: '',
            sales_representative: 'Nicht zugeordnet',
            user: 'Nicht zugeordnet',
            city: '',
            country: '',
            zipcode: '',
            status: 1
          },
          error: {
            name: false,
            number: false
          },
          errorMessage: '',
          loading: false,
          showInput: false,
          changeData: {
            data: false,
            sales_representative: false,
            project_number: false,
            nutzer: false,
          },
          projectId: '',
          users: [],
          unassigned: 'Nicht zugeordnet',
          userFilter: {
            mode: 'multiple',
            value: null,
            placeholder: 'Nutzer',
            options: [],
            maxHeight: 300
          },
          fetchUsers: [],
          noData: false,
        }
      },
      created() {
        this.getData();
      },
      computed: {
        ...mapGetters(['getSalesUsers'])
      },
      methods: {
        getData() {
          this.$store.dispatch('getActiveUsers').then((response) => {
            this.users = response.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
          }).catch((error) => {
            console.log(error);
          });
          this.$store.dispatch('setUsers').then(() => {
            this.fetchUsers = this.getSalesUsers;
          })
          if (this.$route.params.id) {
            this.$store.dispatch('getProject', this.$route.params.id).then((response) => {
              if(Object.prototype.hasOwnProperty.call(response.data, 'sales_representative') === false) {
                response.data.sales_representative = '';
              } else if(Object.prototype.hasOwnProperty.call(response.data, 'number') === false || /[a-zA-Z]/i.test(response.data.number)) {
                response.data.number = '';
              }

              if(!response.data.user) {
                response.data.user = 'Nicht zugeordnet';
              }

              this.model = response.data;
              if (response.data.status === 2) {
                this.changeData.data = false;
                this.changeData.sales_representative = false;
                this.changeData.project_number = false;
                this.changeData.nutzer = false;
              } else if (response.data.status === 3) {
                this.changeData.data = true;
                this.changeData.sales_representative = false;
                this.changeData.project_number = false; 
              } else if (response.data.status === 5 && response.data.number && response.data.sales_representative || response.data.status === 6 && response.data.number && response.data.sales_representative) {
                  this.changeData.data = true;
                  this.changeData.sales_representative = true;
                  this.changeData.project_number = true;
              } else if(response.data.user !== 'Nicht zugeordnet' && response.data.user) {
                 this.changeData.data = true
              }
              if(response.data.status === 2) {
               this.changeData.nutzer = false;
              } else if(response.data.user === 'Nicht zugeordnet' || !response.data.user) {
                this.changeData.nutzer = false;
              } else {
                this.changeData.nutzer = true;
              }
              this.projectId = response.data._id;
            }).catch((error) => {
              this.loading = false;
              this.errorMessage = error.response.data.error.message;
            })
          }
        },
        saveProject() {
          if (!this.loading) {
            this.loading = true;
            if (!this.$route.params.id) {
              if(this.model.user === 'Nicht zugeordnet') {
                this.model.status = 1;
              } else {
                this.model.status = 2;
              }
            }

            if(this.model.user !== 'Nicht zugeordnet' && this.model.status === 1) {
              this.model.status = 2;
            }
            let formData = {
              name: this.model.name,
              number: this.model.number,
              sales_representative: this.model.sales_representative,
              user: this.model.user,
              city: this.model.city,
              country: this.model.country,
              zipcode: this.model.zipcode,
              status: this.model.status
            };

            if(formData.user === 'Nicht zugeordnet') {
              formData = _.omit(formData,'user');
            }

            if (this.projectId) {
              formData['_id'] = this.projectId;
            }
            this.$store.dispatch('saveProject', formData).then(() => {
              this.loading = false;
              this.$router.push('/projects')
            }).catch((error) => {
              this.loading = false;
              if(error.data.error.message.search('name') > -1) {
                this.error.name = true;
                this.errorMessage = "Name der Liegenschaft existiert bereits.";
              } else if (error.data.error.message.search('number') > -1) {
                this.error.number = true;
                this.error.name = false;
                this.errorMessage = "Projektnummer existiert bereits.";
              }
              this.errorMessage = error.response.data.error.message;
            })
          }
        }
      }
    }
);
